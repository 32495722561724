@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --bg-primary: 255, 255, 255;
    --bg-secondary: 245, 246, 247;
    --bg-tertiary: 238, 238, 238;

    --text-primary: 0, 0, 0;
    --text-secondary: 56, 63, 74;
    --text-tertiary: 255, 255, 255;
  }
  .venue {
    --bg-primary: 41, 41, 60;
    --bg-secondary: 61, 61, 80;
    --bg-tertiary: 81, 81, 100;
  }
  .customer {
    --bg-primary: 255, 255, 255;
    --bg-secondary: 96,130,182;
    --bg-tertiary: 137, 148, 153;
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
